import React from "react"
import PropTypes from "prop-types"
import Header from "../organisms/header"
import MainHeader from "../organisms/mainHeader"
import Footer from "../organisms/footer"
import GlobalStyle from "../../theme/globalStyles"
import { ThemeProvider } from "styled-components"
import { theme } from "../../theme/theme"

const Layout = ({ children, pageNumber }) => (
  <main style={{ overflowX: "hidden" }}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {typeof window !== "undefined" ? (
        window.location.pathname === "/" ? (
          <MainHeader />
        ) : (
          <Header pageNumber={pageNumber} />
        )
      ) : (
        ""
      )}
      {children}
      <Footer />
    </ThemeProvider>
  </main>
)

export default Layout

Layout.propTypes = {
  children: PropTypes.array,
}
