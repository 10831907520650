import React from "react"
import styled from "styled-components"
import GoTop from "../../assets/images/Icons/arrows/icon-goTop.svg"

const GoToTopBox = styled.div`
  position: absolute;
  top: -25px;
  right: 7%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`

const GoToTop = () => {
  return (
    <GoToTopBox onClick={() => document.documentElement.scrollTo({ top: 0, behavior: "smooth" })}>
      <img src={GoTop} alt="Top arrow" />
    </GoToTopBox>
  )
}

export default GoToTop
