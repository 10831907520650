import { createGlobalStyle } from "styled-components"
import RedHatDisplay from "../theme/fonts/Red_Hat_Display/RedHatDisplay-Regular.ttf"
import RedHatDisplayBold from "../theme/fonts/Red_Hat_Display/RedHatDisplay-Bold.ttf"
import RedHatDisplayMedium from "../theme/fonts/Red_Hat_Display/RedHatDisplay-Medium.ttf"
import RedHatDisplayThin from "../theme/fonts/Red_Hat_Display/RedHatDisplay-Regular.ttf"

const GlobalStyle = createGlobalStyle`
  //Normal
  @font-face{
    font-family: "Red Hat Display";
    src: url(${RedHatDisplay}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  //Bold
  @font-face{
    font-family: "Red Hat Display";
    src: url(${RedHatDisplayBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  //Medium
  @font-face{
    font-family: "Red Hat Display";
    src: url(${RedHatDisplayMedium}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  //Thin
  @font-face{
    font-family: "Red Hat Display";
    src: url(${RedHatDisplayThin}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  html{
    @media(max-width: 1429px){
      font-size: 15px;
    }
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Red Hat Display', sans-serif;
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;
    @media print{
      #footer{
        display: none;
      }
      #offert{
        display: none;
      }
    }
  }
  ul{
      margin: 0;
      padding: 0;
  }
  li{
    list-style: none;
  }
`

export default GlobalStyle
