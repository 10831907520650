import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import Li from "../atoms/li"
import Logo from "../atoms/logo"
import MobileNav from "../atoms/menuLines"
import NavLink from "../atoms/anchorLink"
import Link from "../atoms/link"

const SlideLeft = keyframes`
    0%{
        left: 100%;
    }
    100%{
        left: 0;
    }
`

const SlideOut = keyframes`
    0%{
        left: 0;
    }
    100%{
        left: 100%;
    }
`

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  @media (max-width: 1279px) {
    flex-wrap: wrap;
  }
  @media (max-width: 991px) {
    padding: 5px;
  }
  @media (min-width: 992px) {
    width: 100%;
  }
`

const Links = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100vw;
    opacity: ${({ hidden }) => (hidden ? 0 : 1)};
    left: 0;
    position: absolute;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    top: 84px;
    z-index: 3;
    transition: 0.3s ease;
    text-align: center;
    animation: 0.5s ease forwards;
    animation-name: ${({ hidden }) => (hidden ? SlideOut : SlideLeft)};
  }
`

const LogoLink = styled(Link)`
  @media (max-width: 991px) {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
  }
`

const Nav = () => {
  const [isHidden, setHidden] = useState(true)

  return (
    <NavBar>
      <MobileNav onClick={() => (isHidden ? setHidden(false) : setHidden(true))} toggle={isHidden} />
      <LogoLink href="/">
        <Logo />
      </LogoLink>
      <Links hidden={isHidden} onClick={() => (isHidden ? setHidden(false) : setHidden(true))} toggle={isHidden}>
        <NavLink to="/#top">
          <Li>Start</Li>
        </NavLink>
        <NavLink to="/#others">
          <Li>Nasze inwestycje</Li>
        </NavLink>
        <NavLink to="/#contact">
          <Li>Kontakt</Li>
        </NavLink>
      </Links>
    </NavBar>
  )
}

export default Nav
