import styled from "styled-components"

const Link = styled.a`
  text-decoration: none;
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  position: relative;
  &:hover {
    opacity: 1;
  }
`

export default Link
