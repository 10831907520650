import React from "react"
import Nav from "../molecules/mainNav"
import styled from "styled-components"
import SEO from "../organisms/seo"

const HeaderBox = styled.header`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  top: 0;
  position: relative;
  z-index: 1;
  @media (max-width: 991px) {
    padding: 10px 0 10px 0;
  }
`

const MainHeader = () => {
  return (
    <HeaderBox id="top">
      <SEO />
      <Nav />
    </HeaderBox>
  )
}

export default MainHeader
