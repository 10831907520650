import React from "react"
import styled from "styled-components"
import NavLink from "../atoms/anchorLink"
import Li from "../atoms/li"
import A from "../atoms/link"
import GoToTop from "../molecules/goToTop"
import { Icon } from "@iconify/react"

const FooterBox = styled.footer`
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  position: relative;
`

const Links = styled.div`
  display: flex;
  color: white;
  @media (max-width: 991px) {
    flex-direction: column;
    text-align: center;
  }
`
const Link = styled(Li)`
  color: white;
  margin-left: 0;
  margin-right: 20px;
  @media (max-width: 991px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const CompanyLink = styled(A)`
  color: white;
  transition: 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
`

const CopyBox = styled.div`
  display: flex;
  color: #fff;
`

const Copy = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  opacity: 0.4;
  font-size: 0.9rem;
`

const LawBox = styled.div`
  width: 50%;
  align-self: flex-start;
  @media (max-width: 991px) {
    align-self: center;
    text-align: center;
    width: 100%;
  }
`

const Footer = () => {
  return (
    <FooterBox id="footer">
      <GoToTop />
      <Links>
        {typeof window !== "undefined" ? (
          window.location.pathname === "/" ? (
            <>
              <NavLink to="/#top">
                <Link>Start</Link>
              </NavLink>
              <NavLink to="/#others">
                <Link>Nasze inwestycje</Link>
              </NavLink>
              <NavLink to="/#contact">
                <Link>Kontakt</Link>
              </NavLink>
            </>
          ) : (
            <>
              <NavLink to="/1#top">
                <Link>Start</Link>
              </NavLink>
              <NavLink to="/1#about">
                <Link>O osiedlu</Link>
              </NavLink>
              <NavLink to="/1#localization">
                <Link>Lokalizacja</Link>
              </NavLink>
              <NavLink to="/1#offert">
                <Link>Domy</Link>
              </NavLink>
              <NavLink to="/1#standards">
                <Link>Standard</Link>
              </NavLink>
              <NavLink to="/1#others">
                <Link>Nasze inwestycje</Link>
              </NavLink>
              <NavLink to="/1#contact">
                <Link>Kontakt</Link>
              </NavLink>
            </>
          )
        ) : (
          ""
        )}
      </Links>
      <CopyBox>
        <Copy>Copyright ©{new Date().getFullYear()} DOMSET Nieruchomości</Copy>
      </CopyBox>
      <CopyBox>
        <p>
          Stworzone z <Icon icon="noto:heart-suit" style={{ transform: "translateY(20%)" }} /> przez{" "}
          <CompanyLink href="https://rend.pro/offer/investment-website/">Rend.Pro</CompanyLink>
        </p>
      </CopyBox>
      <LawBox>
        <Copy>
          Treści znajdujące się na stronie internetowej nie stanowią oferty w rozumieniu przepisów prawa i winny być
          interpretowane wyłącznie jak informacja, o których jest mowa w art. 71 Kodeksu Cywilnego.
        </Copy>
      </LawBox>
    </FooterBox>
  )
}

export default Footer
