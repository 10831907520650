import { Availability } from "../types/availability"

export const theme = {
  colors: {
    primary: "#21c5c6",
    secondary: "#14172a",
    gray: "#c4c4c4",
    lightGray: "#f8f8f8",
    black: "#000000",
    white: "#ffffff",
  },
  fontSize: {
    small: `0.9rem`,
    medium: `1.1rem`,
    big: `1.7rem`,
    pageHeader: `2.6rem`,
    pageHeaderSmall: `2.2rem`,
  },
  [Availability.Available]: "#21c5c6",
  [Availability.Reserved]: "#CC8500",
  [Availability.Unavailable]: "#E60000",
}
