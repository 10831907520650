import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const HashLink = styled(AnchorLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  position: relative;
`

export default HashLink
