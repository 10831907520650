import styled from "styled-components"

const Li = styled.li`
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    opacity: 0;
    transition: 0.2s ease;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 991px) {
      display: none;
    }
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export default Li
