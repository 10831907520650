import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const LogoBox = styled.div`
  margin-right: 30px;
  @media (max-width: 1279px) {
    margin-right: 0;
  }
`

const LogoSvg = styled.img`
  width: 200px;
  height: 50px;
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "Logo.svg" }) {
        id
        extension
        publicURL
      }
    }
  `)

  return (
    <LogoBox>
      <LogoSvg src={data.file.publicURL} alt="Brochacki" />
    </LogoBox>
  )
}

export default Logo
